/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { KTIcon } from '../../../_metronic/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import MyResearchButtons from './MyResearchButtons';



interface QAInterfaceProps {
    onFirstQuestionAsked: (question: string) => void;
    currentQuestion: string;
}

interface Data {
    mainquestion?: string;
    section?: string;
    additionalInformation?: string;
    secondaryQuestion?: string;
    link?: string;
}

interface Answer {
    text: string;
    valid: boolean;
}

interface QAPair {
    question: string;
    answer: Answer;
    error: boolean;
}

interface CustomTreeNode {
    key: string;
    label: string;
    parent?: string;
    data?: string;
    isAddDomain?: boolean;
    children?: CustomTreeNode[];
}

const QAInterface1: FC<QAInterfaceProps> = ({ onFirstQuestionAsked, currentQuestion }) => {

    const [question, setQuestion] = useState<string>('');
    const [qaPairs, setQAPairs] = useState<QAPair[]>([]);
    const [firstQuestionAsked, setFirstQuestionAsked] = useState<boolean>(currentQuestion !== '' ? true : false);
    // const qaContainerRef = useRef<HTMLDivElement | null>(null);
    const [isHovered,setIsHovered] = useState(false);
    const [questionIndex,setQuestionIndex] = useState(200);
    const [isFullQuestionShown, setIsFullQuestionShown] = useState(false);


    const [selectedButton, setSelectedButton] = useState('Summarize');
    const [selectedOption, setSelectedOption] = useState('Text');

    const additionalInformation = useRef({
        'text': '',
        'document': {
            0: '',
            1: '',
            2: '',
        },
        'url': ''
    });

    const buttonQuestions = {
        Summarize: 'Summarize the content into a paragraph',
        Translate: 'Please translate the material to {language} ',
        Impact: 'Please provide an impact statement for this material ',
        Action: 'What are the actions arising out of this material? ',
        FAQ: 'Please prepare Frequently Asked Questions  (FAQs) and responses for this material ',
        'Compare Documents': 'Please compare the documents for differences and similarities'
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).catch(() => {
            alert('Failed to copy.');
        });
    };

    const clearQuestion = () => {
        setQuestion('');
    }

    const handleDownload = (question, answer,) => {
        if (typeof answer !== 'object') {
            console.error('Invalid answer format. Expected an object.');
            return;
        }
        let answerContent = `Question: ${question}\n\nAnswer:\n${answer?.text}\n\n`;

        const blob = new Blob([answerContent], { type: 'text/plain' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = fileURL;
        link.download = `${question}_answer.txt`;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
    };

    useEffect(() => {
        setQuestion(buttonQuestions[selectedButton])
    }, [selectedButton]);

    const askQuestion = async () => {
        const framedQuestion = question;

        setQAPairs(prevQAPairs => [
            ...prevQAPairs,
            {
                question: framedQuestion,
                answer: { text: 'Thinking ...', valid: true },
                error: false
            }
        ]);

        try {
            const API_URL = `${process.env.REACT_APP_DEEP_API_URL}/process-query`;
            
            // const API_URL = `http://127.0.0.1:8000/process-query`;



            const payLoad = new FormData();
            payLoad.append('question', question ? question : ' ');
            payLoad.append('additionalInformationFile1', additionalInformation.current['document'][0]);
            payLoad.append('additionalInformationFile2', additionalInformation.current['document'][1]);
            payLoad.append('additionalInformationFile3', additionalInformation.current['document'][2]);
            payLoad.append('additionalInformationLink', additionalInformation.current['url']);

            const response = await fetch(API_URL, {
                method: 'POST',
                body: payLoad,
            });

            if (!response.body) {
                throw new Error("No response body available for streaming");
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let receivedText = "";

            setQAPairs(prevQAPairs => {
                const updatedQAPairs = [...prevQAPairs];
                updatedQAPairs[updatedQAPairs.length - 1] = {
                    question: framedQuestion,
                    answer: {
                        text: "",
                        valid: true
                    },
                    error: false
                };
                return updatedQAPairs;
            });

            // Read and process the stream
            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                // Decode and append the received text
                receivedText += decoder.decode(value, { stream: true });

                // Update the last QAPair with the streamed characters
                setQAPairs(prevQAPairs => {
                    const updatedQAPairs = [...prevQAPairs];
                    updatedQAPairs[updatedQAPairs.length - 1].answer.text = receivedText;
                    return updatedQAPairs;
                });
            }

        } catch (error) {
            setQAPairs(prevQAPairs => {
                const updatedQAPairs = [...prevQAPairs];
                updatedQAPairs[updatedQAPairs.length - 1] = {
                    question: framedQuestion,
                    answer: {
                        text: "Document can not be parsed",
                        valid: true
                    },
                    error: false
                };
                return updatedQAPairs;
            });
        }

        setQuestion(buttonQuestions[selectedButton]);
    };

    const toggleQuestionView = () => {
        setIsFullQuestionShown(!isFullQuestionShown);
    };

    const clearQuestions = () => {
        setQAPairs([]);
        setQuestion(buttonQuestions[selectedButton]);
        additionalInformation.current = {
        'text': '',
        'document': {
            0: '',
            1: '',
            2: '',
        },
        'url': ''
    }

    }
    return (
        <div className="card-body" style={{ maxHeight: '100vh', width: '100%', position: 'relative' }}>
            <MyResearchButtons
                selectedButton={selectedButton}
                setSelectedButton={setSelectedButton}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                additionalInformation={additionalInformation}
                askQuestion={askQuestion}
                clearQuestions ={clearQuestions}
            />

            <div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex position-relative" style={{ flex: 1 }}>
                        <textarea
                            className="form-control pe-16 text-dark"
                            placeholder={firstQuestionAsked ? "Please ask a follow-up question" : "Please ask a research question"}
                            value={question}
                            rows={3}
                            onChange={(e) => setQuestion(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    if (e.ctrlKey) {
                                        // Trigger askQuestion on Ctrl+Enter
                                        if (question.trim() !== '') {
                                            askQuestion();
                                        }
                                        e.preventDefault(); // Prevent new line for Ctrl+Enter
                                    }
                                }
                            }}
                            style={{ paddingRight: '50px', resize: 'none' }}
                            maxLength={5000}
                        />
                        {question.trim() !== '' && (
                            <>
                                <div>
                                    <button onClick={clearQuestion} className="position-absolute end-0 top-50 translate-bottom-y form-control-button align-items-center justify-content-center" style={{ background: 'none', border: 'none' }}>
                                        <KTIcon
                                            iconName="cross"
                                            iconType="duotone"
                                            className="text-dark fs-1 mx-2 mt-2 me-12 icon-color"
                                        />
                                    </button>
                                    <div style={{ borderLeft: '1px solid #d3d3d3', height: '24px', position: 'absolute', top: '50%', transform: 'translateY(20%)', right: '36px' }}></div>
                                </div>

                                <button
                                    onClick={() => askQuestion()}
                                    className="position-absolute end-0 top-50 translate-bottom-y form-control-button align-items-center justify-content-center"
                                    style={{ background: 'none', border: 'none' }}
                                    disabled={question.trim() === ''}
                                >
                                    <KTIcon
                                        iconName="arrow-right"
                                        iconType="duotone"
                                        className="text-dark fs-1 mx-2 mt-2 me-1 icon-color"
                                    />
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Scrollable container */}
            <div
                className="mb-5 mt-8"
                style={{
                    maxHeight: 'calc(80vh - 100px)',
                    overflowY: 'auto',
                    paddingBottom: '50px',
                    position: 'relative',
                }}
                
            >
                {qaPairs.slice(0).reverse().map((qaPair, index) => (
                    <div key={index} className={`d-flex flex-column ${index !== 0 ? 'mt-3' : ''}`}>
                        {/* Question Box */}
                        <div className={`d-flex justify-content-start ${index !== 0 ? 'mt-2' : ''} mb-2`}>
                            <div className={`p-7 ps-11 fs-6 text-gray-900 fw-medium rounded bg-light-primary`} style={{ width: '100%', wordWrap: 'break-word' }}>
                                <h1 className="text-black fw-bold mb-5" style={{ fontSize: '1.25rem' }}>
                                {isFullQuestionShown ? qaPair.question : qaPair.question.slice(0, questionIndex)}
                                {qaPair.question.length > questionIndex && (
                                    <span
                                        style={{ 
                                            color: isHovered ? 'blue' : 'inherit', 
                                            cursor: 'pointer', 
                                            marginLeft: '8px' }}
                                        onClick={toggleQuestionView}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                    >
                                        {isFullQuestionShown ? 'less...' : 'more...'}
                                    </span>
                                )}
                            </h1>
                            </div>
                        </div>

                        {/* Answer Box */}
                        <div className={`d-flex justify-content-start mb-2`}>
                            <div className={`p-7 ps-11 fs-6 text-gray-900 fw-medium rounded ${qaPair.error ? 'bg-danger text-dark' : 'bg-light-info'}`} style={{ width: '100%', wordWrap: 'break-word' }}>
                                {qaPair.answer.text === 'Thinking ...' ? (
                                    <div className="d-flex justify-content-start">
                                        {[...Array(4)].map((_, idx) => (
                                            <div key={idx} className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <div className="d-flex justify-content-end">
                                            <div className="px-5">
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Copy Message</Tooltip>}>
                                                    <div
                                                        onClick={() => copyToClipboard(qaPair.answer.text)}
                                                        style={{ cursor: 'pointer' }}
                                                        title="Copy to clipboard"
                                                    >
                                                        <KTIcon iconName="copy" iconType="duotone" className="fw-bold fs-1" />
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                            <div>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Download message</Tooltip>}>
                                                    <i
                                                        className="bi bi-download text-primary text-bold fs-1 me-1"
                                                        onClick={() => handleDownload(qaPair.question, qaPair.answer)}
                                                    ></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>

                                        <div>
                                            <h3 className="fw-bold mb-3 text-dark">Lexim Response:</h3>
                                            <ReactMarkdown className="mb-5">{qaPair.answer.text}</ReactMarkdown>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    );
};


export { QAInterface1 };